import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<iframe\n  title=\"promotion-designer-preview\"\n  style={{this.style}}\n  srcdoc={{this.srcDoc}}\n  class=\"w-full h-full rounded origin-top\"\n  ...attributes\n></iframe>", {"contents":"<iframe\n  title=\"promotion-designer-preview\"\n  style={{this.style}}\n  srcdoc={{this.srcDoc}}\n  class=\"w-full h-full rounded origin-top\"\n  ...attributes\n></iframe>","moduleName":"partner/components/template-designer/html-preview.hbs","parseOptions":{"srcName":"partner/components/template-designer/html-preview.hbs"}});
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Component from '@glimmer/component';
import type { DeviceType } from '@ss/arc/arc/types';
import { tracked } from '@glimmer/tracking';
import { getMaxHeight, getMaxWidth } from '@ss/arc/utils/blocks';
import ThemeModel from 'partner/models/theme';

interface TemplateDesignerPreviewSignature {
  Element: HTMLDivElement;
  Args: {
    deviceType: DeviceType;
    srcDoc: string;
    containerHeight?: number;
    containerWidth: number;
    theme?: ThemeModel;
    hasRulesEnabled: boolean;
  };
}

export default class TemplateHtmlPreviewComponent extends Component<TemplateDesignerPreviewSignature> {
  @tracked iframeHeight = 0;

  get deviceType() {
    return this.args.deviceType || 'desktop';
  }

  get width() {
    return getMaxWidth(this.deviceType);
  }

  get height(): number {
    return this.args.deviceType ? getMaxHeight(this.deviceType) : getMaxHeight(this.deviceType) / this.scale;
  }

  get scale() {
    if (!this.args.deviceType) return this.args.containerWidth / this.width;
    const scaleWidth = this.args.containerWidth ? this.args.containerWidth / this.width : 1;
    const scaleHeight = this.args.containerHeight ? this.args.containerHeight / getMaxHeight(this.deviceType) : 1;
    return Math.min(scaleWidth, scaleHeight, 1);
  }

  get style() {
    return `width: ${this.width}px; height: ${this.height}px; transform: scale(${this.scale});
    `;
  }

  get theme() {
    return (
      this.args.theme || {
        colorElement: '#00c3c3',
        colorBackground: '#ffffff',
        colorText: '#000000',
        colorButton: '#00c3c3',
        fontHeading: 'Open Sans',
        fontParagraph: 'Open Sans',
      }
    );
  }

  get srcDoc() {
    let { srcDoc } = this.args;
    srcDoc = srcDoc.replace('{{custom-styles}}', '');
    srcDoc = srcDoc.replace('{{custom-content}}', '');

    const html = String.raw;
    return html`
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          <title>Design Preview</title>

          <script src="https://unpkg.com/chroma-js@2.4.2/chroma.min.js"></script>
          <script src="https://unpkg.com/morphdom@2.7.0/dist/morphdom-umd.min.js"></script>
          <script src="https://cdn.tailwindcss.com"></script>
          <script>
            tailwind.config = {
              corePlugins: {
                preflight: false,
              },
            };
          </script>
          <link href="//fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

          <link
            rel="stylesheet"
            type="text/css"
            href="https://unpkg.com/@tailwindcss/typography@0.1.2/dist/typography.min.css"
          />
          <link
            href="//fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Aleo:ital,wght@0,100..900;1,100..900&family=Asap+Condensed:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=BioRhyme:wght@200..800&family=Bitter:ital,wght@0,100..900;1,100..900&family=Caladea:ital,wght@0,400;0,700;1,400;1,700&family=Caveat:wght@400..700&family=Chivo:ital,wght@0,100..900;1,100..900&family=Courgette&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Dancing+Script:wght@400..700&family=Enriqueta:wght@400;500;600;700&family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Great+Vibes&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Pacifico&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rochester&family=Rokkitt:ital,wght@0,100..900;1,100..900&family=Sacramento&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
            rel="stylesheet"
          />
          <link href="//fonts.googleapis.com/css?family=Source Sans Pro" rel="stylesheet" />
          <link href="//fonts.googleapis.com/css?family=Source Serif Pro" rel="stylesheet" />
          <link href="//fonts.googleapis.com/css?family=Muli" rel="stylesheet" />

          <style>
            :root {
              --arc-ss-color-primary: ${this.theme.colorElement};
              --arc-ss-color-background: ${this.theme.colorBackground};
              --arc-ss-color-text: ${this.theme.colorText};
              --arc-ss-color-button: ${this.theme.colorButton};
              --arc-ss-font-heading: ${this.theme.fontHeading};
              --arc-ss-font-paragraph: ${this.theme.fontParagraph};
            }
            html {
              scrollbar-width: none;
            }

            body {
              margin: 0;
              padding: 0;
            }

            a {
              pointer-events: none;
            }

            iframe {
              width: 100%;
              height: auto;
              aspect-ratio: 16 / 9;
            }

            ${this.scale < 0.6
              ? `@-moz-document url-prefix() {
              .ssAppContentAreaContentNew div{
                border-width: ${this.args.deviceType ? '2px' : '3px'} !important;
              }
            }`
              : ''}


            ${this.args.hasRulesEnabled ? '' : 'a[data-design-template-type-id="11"] { display: none; }'}
          </style>
        </head>
        ${srcDoc}
      </html>
    `;
  }
}
