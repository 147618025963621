import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import BaseModel from 'secondstreet-common/models/base';

import type CurrentService from 'partner/services/current';

export default class ThemeModel extends BaseModel {
  @service declare current: CurrentService;

  @attr('number') declare organizationId: number;
  @attr('number') declare hierarchyLevel: number;
  @attr('string') declare name: string;
  @attr('string') declare fontHeading: string;
  @attr('string') declare fontParagraph: string;
  @attr('string') declare colorButton: string;
  @attr('string') declare colorElement: string;
  @attr('string') declare colorBackground: string;
  @attr('string') declare colorText: string;
  @attr('boolean') declare isUsed: string;

  get isInherited() {
    return this.organizationId != this.current.organization.id;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    theme: ThemeModel;
  }
}
