/* globals jstz */
import Controller from '@ember/controller';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Arc from '@ss/arc/arc/arc';
import { ArcBlock, DeviceType } from '@ss/arc/arc/types';
import { task } from 'ember-concurrency';
import SettingsService from 'partner/services/settings';
import SnackbarService from 'secondstreet-common/services/snackbar';
import type CurrentService from 'partner/services/current';
import type Store from '@ember-data/store';
import SettingModel from 'partner/models/setting';
import { findFooterBlock, findPromotionBlock } from '@ss/arc/utils/blocks';
import { Token } from 'partner/models/token';
import type PreviewService from 'partner/services/preview';
import { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/designs/current';
import { dateRange } from 'secondstreet-common/utils/calendars';
import SessionService from 'partner/services/session';
import RouterService from '@ember/routing/router-service';
import DesignTemplateModel from 'partner/models/design-template';
import type ThemeModel from 'partner/models/theme';
import { getContrastingColor } from 'secondstreet-common/helpers/get-contrasting-color';
import RSVP from 'rsvp';
import { later, next } from '@ember/runloop';
import enums from 'ember-cli-ss-enums/services/enums';
import { ArcContent } from '@ss/arc/arc';
import TemplateDesignerService from 'partner/services/template-designer';
import mostCurrentMatchup from 'partner/utils/most-current-matchup';

const designTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Custom Template' });
const layoutTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Custom Layout' });
const promotionTypesWithRulesAlwaysEnabled = ['UGCVoting', 'Sweepstakes', 'UGCSweepstakes', 'Ballot'];

const promotionTypeIds = [
  enums.findWhere('PROMOTION_TYPE', { name: 'UGCVoting' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'Sweepstakes' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'Quiz' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'Survey' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'UGCSweepstakes' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'UGCGallery' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'Ballot' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'Poll' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'VotingBracket' }),
  enums.findWhere('PROMOTION_TYPE', { name: 'EventSignup' }),
];

const promotionViews = {
  SweepstakesSimple: ['Entry Form', 'Thanks Page'],
  SweepstakesCodeword: ['Entry Form', 'Thanks Page'],
  QuizTrivia: ['Quiz', 'Registration Form', 'Thanks Page'],
  QuizPersonality: ['Quiz', 'Registration Form', 'Thanks Page'],
  PollStandard: ['Poll', 'Registration Form'],
  Survey: ['Entry Form', 'Thanks Page'],
  VotingBallot: ['Groups Page', 'Gallery', 'Entrant Page'],
  NominationAndVotingBallot: ['Groups Page', 'Gallery', 'Entrant Page'],
  VideoVotingStandard: ['Gallery', 'Entry Form', 'Thank-You Message', 'Entrant Page'],
  UGCSweepstakesVideo: ['Gallery', 'Entry Form', 'Thank-You Message', 'Entrant Page'],
  PhotoVotingStandard: ['Gallery', 'Entry Form', 'Thank-You Message', 'Entrant Page'],
  PhotoGallery: ['Gallery', 'Entry Form', 'Thank-You Message', 'Entrant Page'],
  UGCSweepstakesStandard: ['Gallery', 'Entry Form', 'Thank-You Message', 'Entrant Page'],
  VotingBracket: ['Matchup Page'],
  EventSignup: ['Entry Form', 'Thanks Page'],
};

const copyArcContent = (content: ArcContent) => {
  return new ArcContent(JSON.parse(JSON.stringify(content)));
};

const getTemplateJSON = (content: ArcContent) => {
  if (!content) return;
  const copy = copyArcContent(content);
  const promotionBlock = findPromotionBlock(copy.blocks);

  if (promotionBlock) {
    promotionBlock.blocks.clear();
  }

  return JSON.stringify(copy.toJSON());
};

export default class TemplateCustomizationController extends Controller {
  @service declare snackbar: SnackbarService;
  @service declare settings: SettingsService;
  @service declare store: Store;
  @service declare current: CurrentService;
  @service declare preview: PreviewService;
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare permissions: any;
  @service declare templateDesigner: TemplateDesignerService;

  @tracked deviceType: DeviceType = 'desktop';
  @tracked selectedLibraryTab = 'elements';
  @tracked selectedLibrarySettingsTab = 'attributes';
  @tracked showPreview = false;
  @tracked content?: any;
  @tracked arc?: Arc;
  @tracked selectedView = '';
  @tracked expandedSections: string[] = [];
  @tracked newTemplateSetting?: SettingModel;
  @tracked refresh = new Date();
  @tracked refreshLayouts = new Date();
  @tracked showSaveTemplateModal = false;
  @tracked newTemplateDesign?: DesignTemplateModel;
  @tracked newTemplateLayout?: DesignTemplateModel;
  @tracked showSwitchTemplateModal = false;
  @tracked isSaveDirty = false;
  @tracked blockToInject?: ArcBlock;
  @tracked error = '';
  @tracked editTemplateForPermissions?: DesignTemplateModel;

  declare model: Model;

  facebookToken?: Token;
  xToken?: Token;
  emailToken?: Token;
  thanksToken?: Token;
  galleryLayoutToken?: Token;

  get canAdminister() {
    return this.permissions.getAccessLevel('MessageBodyTemplate,MessageBodyTemplateToken,Token').administer;
  }

  get canView() {
    return this.permissions.getAccessLevel('MessageBodyTemplate,MessageBodyTemplateToken,Token').view;
  }

  get views() {
    const promotionSubType = this.current.promotion.promotionSubType as keyof typeof promotionViews;
    const views = [...promotionViews[promotionSubType]];
    if (this.isRegistrationDisabled) {
      views.splice(views.indexOf('Registration Form'), 1);
    }

    if (this.current.promotion.isBallot && !this.hasGroups) {
      views.splice(views.indexOf('Groups Page'), 1);
    }
    return views;
  }

  get mandatoryContentCustomName() {
    switch (this.current.promotion.promotionTypeName) {
      case 'Sweepstakes':
      case 'EventSignup':
      case 'Survey':
        return 'Form';
      case 'UGCSweepstakes':
      case 'UGCVoting':
      case 'UGCGallery':
        return 'Gallery';
      case 'Ballot':
        return 'Ballot Gallery';
      case 'Quiz':
        return 'Quiz';
      case 'Poll':
        return 'Poll';
      case 'VotingBracket':
        return 'Bracket';
    }
    return '';
  }

  get layoutType() {
    return this.current.promotion.isPoll
      ? enums.findWhere('POLL_LAYOUTS', { value: this.galleryLayoutToken.value }, 'name')
      : this.current.promotion.isBallot
      ? enums.findWhere('BALLOT_LAYOUTS', { value: this.galleryLayoutToken.value }, 'name')
      : enums.findWhere('GALLERY_LAYOUTS', { value: this.galleryLayoutToken.value }, 'name');
  }

  get isSmallPoll() {
    return this.layoutType == 'Small';
  }

  get registrationType() {
    return enums.findWhere('REGISTRATION_TYPE', { id: this.settings.getValueFor('registration_type') }, 'name');
  }

  get isRegistrationRequired() {
    return this.registrationType == 'Required';
  }

  get isRegistrationOptional() {
    return this.registrationType == 'Optional';
  }

  get isRegistrationDisabled() {
    return this.registrationType == 'Disabled';
  }

  get hasCategories() {
    return this.model.matchups.length > 1;
  }

  get hasGroups() {
    return this.model.matchupGroups.length > 1;
  }

  get enableSaveLayout() {
    return (
      this.arc?.selected &&
      !['promotion', 'footer'].includes(this.arc.selected.type) &&
      !findPromotionBlock(this.arc.selected.blocks) &&
      !findFooterBlock(this.arc.selected.blocks)
    );
  }

  get hasRulesEnabled() {
    return (
      this.model.sweepstakes.isEnabled ||
      promotionTypesWithRulesAlwaysEnabled.includes(this.current.promotion.promotionType)
    );
  }

  get previewHeight() {
    return (document.querySelector('.template-designer--background')?.clientHeight || 600) - 40;
  }

  get previewSrcDoc() {
    if (!this.arc) return;

    const { countdownElement } = this.arc;

    if (!countdownElement || countdownElement.data.endDateTargetType == 3) {
      return this.arc.srcDoc;
    }

    let { endDate } = this.current.organizationPromotion;
    if (countdownElement.data.endDateTargetType == 2) {
      if (this.current.promotion.isBallot) {
        if (this.current.promotion.isNominationAndVotingBallot) {
          //for nomination and voting ballot, if submission is over then show the selection end date
          endDate = this.current.organizationPromotion.submissionHasEnded
            ? this.current.organizationPromotion.selectionEndDate
            : this.current.organizationPromotion.submissionEndDate;
        }
      } else {
        //find the relevant round and get the end date
        const matchup = mostCurrentMatchup(this.model.matchups);
        endDate = matchup?.selectionEndDate;
      }
    }

    if (this.current.promotion.isEventSignup) {
      endDate = this.current.organizationPromotion.submissionEndDate;
    }

    const tempArc = new Arc(copyArcContent(this.arc.content));
    if (tempArc.countdownElement) tempArc.countdownElement.data.targetDate = endDate;
    return tempArc.srcDoc;
  }

  get selectedTheme() {
    return this.model.themes.find(theme => +theme.id == this.settings.getValueFor('default_theme_id'));
  }

  get hasLockedTemplate() {
    return this.model.designTemplate.isInherited && this.model.designTemplate.isLocked;
  }

  @action
  toggleShowPreview() {
    this.showPreview = !this.showPreview;
    if (!this.showPreview && this.deviceType == 'mobile_landscape') {
      this.deviceType = 'mobile';
    }
    this.resetSelection();
  }

  @action
  onClickSaveAs() {
    if (!this.arc) return;
    this.newTemplateDesign = this.store.createRecord('design-template', {
      designTemplateTypeId,
      designMethodTypeId: 1,
      templateContent: getTemplateJSON(this.arc.content),
      organization: this.current.organization,
      promotionTypeIds,
      statusTypeId: enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id'),
      visibleToAll: true,
      isLocked: false,
    });
  }

  @action
  async loadTemplateAndEdit() {
    this.editTemplateForPermissions = await this.model.designTemplate;
  }

  @action
  onCloseSaveAsModal() {
    this.newTemplateDesign?.unloadRecord();
    this.newTemplateDesign = undefined;
    this.newTemplateLayout?.unloadRecord();
    this.newTemplateLayout = undefined;
    this.error = '';
  }

  @action
  onChangeSelectedLibraryTab(tabKey: string) {
    this.selectedLibraryTab = tabKey;
  }

  @action
  onChangeSelectedLibrarySettingsTab(tabKey: string) {
    this.selectedLibrarySettingsTab = tabKey;
  }

  @action
  onSectionExpansionChange(sectionName: string) {
    if (this.expandedSections.includes(sectionName)) {
      this.expandedSections = this.expandedSections.reject(name => name == sectionName);
    } else {
      this.expandedSections = [...this.expandedSections, sectionName];
    }
  }

  @action
  resetExpandedSections() {
    this.expandedSections = [];
  }

  @action
  onDeviceChange(device: DeviceType) {
    this.deviceType = device;
    this.changePromotionView();
    this.resetSelection();
  }

  @action
  setSelectedView(view: string) {
    this.selectedView = view;
    this.changePromotionView();
  }

  @action
  undo() {
    this.arc?.undo();
    this.changePromotionView();
  }

  @action
  redo() {
    this.arc?.redo();
    this.changePromotionView();
  }

  @action
  onSaveAsLayout() {
    if (!this.arc || !this.arc.selected) return;

    this.newTemplateLayout = this.store.createRecord('design-template', {
      designTemplateTypeId: layoutTemplateTypeId,
      designMethodTypeId: 1,
      templateContent: JSON.stringify(this.arc.selected.toJSON()),
      promotionTypeIds,
      organization: this.current.organization,
      statusTypeId: enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id'),
      visibleToAll: true,
      isLocked: false,
    });
  }

  @action
  resetSelection() {
    later(() => {
      this.arc?.reselect();
    }, 300);
  }

  changePromotionView = () => {
    if (!this.arc) return;

    const promotionBlock = findPromotionBlock(this.arc.blocks);
    const reselectPromotionBlock = this.arc.selected == promotionBlock;
    const firstMatchup = this.model.matchups?.firstObject;

    const promotionColor = this.selectedTheme?.colorElement || '#00c3c3';
    const promotionTextColor = getContrastingColor(promotionColor);
    // @ts-ignore
    const timeZone = jstz.determine().name();
    if (promotionBlock) {
      promotionBlock.customName = this.mandatoryContentCustomName;
      promotionBlock.blocks.clear();
      const blocks = this.selectedView.toLowerCase().includes('thank')
        ? this.preview.getThanksPreview({
            hasThanksMessage: this.thanksToken.isEnabled,
            message: this.thanksToken.value,
            promotionName: firstMatchup?.name || '',
            promotionAddress: firstMatchup?.address || '',
            promotionDescription: firstMatchup?.description || '',
            promotionDates:
              firstMatchup?.selectionStartDate && firstMatchup?.selectionEndDate
                ? dateRange(firstMatchup?.selectionStartDate, firstMatchup?.selectionEndDate, timeZone)
                : '',
            layoutType: this.layoutType,
            promotionColor,
            promotionTextColor,
            isQuizTrivia: this.current.promotion.isQuizTrivia,
          })
        : this.selectedView == 'Entry Form' ||
          this.selectedView == 'Registration Form' ||
          this.selectedView == 'Groups Page'
        ? this.preview.getEntryFormPreview({
            isRegistrationOptional: this.isRegistrationOptional,
            promotionColor,
            promotionTextColor,
          })
        : this.selectedView == 'Entrant Page' || this.selectedView == 'Matchup Page'
        ? this.preview.getEntrantPagePreview({
            promotionColor,
            promotionTextColor,
          })
        : this.preview.getPromotionPreview({
            layoutType: this.layoutType,
            isSmall: this.isSmallPoll,
            ballotLayoutType: this.layoutType,
            hasGroups: this.hasGroups,
            hasCategories: this.hasCategories,
            isMobile: this.deviceType == 'mobile',
            promotionColor,
            promotionTextColor,
          });
      promotionBlock.blocks.push(...(blocks as []));
    }

    this.arc.content = new ArcContent(JSON.parse(JSON.stringify(this.arc.content)));

    if (reselectPromotionBlock) {
      const block = findPromotionBlock(this.arc.blocks);
      this.arc.deselect();
      block && this.arc.select(block);
    }
  };

  refreshPromotionPreview = () => {
    this.refresh = new Date();
  };

  refreshThemes = (theme?: ThemeModel) => {
    if (theme) {
      this.model.themes = [...this.model.themes, theme];
    }

    this.refreshPromotionPreview();
    this.changePromotionView();
  };

  scrollToSelectedBlock = () => {
    next(() => {
      const selectedBlock = document.querySelector('[data-block-selected]');
      if (selectedBlock) {
        selectedBlock.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    });
  };

  onAfterCreate = async (block: ArcBlock) => {
    if (block.type == 'social') {
      await this.handleSocialElement();
    }
    this.resetExpandedSections();
    this.scrollToSelectedBlock();
  };

  handleSocialElement = async () => {
    if (this.arc?.socialElement) {
      const { socialElement } = this.arc;

      socialElement.data.facebookTitle = this.facebookToken.title;
      socialElement.data.facebookMediaIemId = this.facebookToken.mediaItemId;

      socialElement.data.xDescription = this.xToken.title;

      socialElement.data.emailSubject = this.emailToken.title;
      socialElement.data.emailDescription = this.emailToken.value;
    }

    await this.saveTemplateTask.perform();
  };

  saveOrgLevelTemplateTask = task({ drop: true }, async () => {
    try {
      this.session.removeCustomHeader('X-Organization-Promotion-Id');
      this.session.removeCustomHeader('X-Promotion-Id');
      await this.newTemplateDesign?.save();
      this.newTemplateDesign = undefined;

      if (this.newTemplateLayout) {
        await this.newTemplateLayout.save();
        this.refreshLayouts = new Date();
        this.newTemplateLayout = undefined;
      }

      if (this.editTemplateForPermissions) {
        await this.editTemplateForPermissions.save();
        this.editTemplateForPermissions = undefined;
      }

      this.error = '';
    } catch (e: any) {
      this.snackbar.exception(e);
      this.error = this.newTemplateLayout
        ? e.errors?.[0]?.detail?.replace('template', 'layout')
        : e.errors?.[0]?.detail;
    } finally {
      this.session.addCustomHeaders({
        'X-Organization-Promotion-Id': this.current.organizationPromotion.id,
        'X-Promotion-Id': this.current.promotion.id,
      });
    }
  });

  updateDirtyFlag = () => {
    if (!this.arc) return;

    this.isSaveDirty =
      this.thanksToken?.hasDirtyAttributes ||
      this.galleryLayoutToken?.hasDirtyAttributes ||
      getTemplateJSON(this.arc.content) !== this.model.design.renderedContent;
  };

  switchTemplateTask = task({ drop: true }, async (template: DesignTemplateModel) => {
    const { design } = this.model;
    setProperties(design, {
      renderedContent: template.templateContent,
      template,
    });

    await design.save();
    await this.templateDesigner.setupSocialElement(design);
    this.showSwitchTemplateModal = false;
    await this.router.refresh();
  });

  switchToOldDesignerTask = task({ drop: true }, async () => {
    const setting = this.model.useOldTemplateSetting as SettingModel;
    setting.value = true;
    setting.forceDirty();
    await setting.save();

    await this.router.refresh();
  });

  saveTemplateTask = task({ keepLatest: true }, async () => {
    if (
      !this.canAdminister ||
      !this.arc ||
      this.saveOrgLevelTemplateTask.isRunning ||
      this.switchTemplateTask.isRunning ||
      this.switchToOldDesignerTask.isRunning
    )
      return;

    try {
      this.updateDirtyFlag();
      this.model.design.renderedContent = getTemplateJSON(this.arc.content);

      if (this.thanksToken?.hasDirtyAttributes) {
        if (this.thanksToken.isEnabled && !this.thanksToken.value) {
          this.snackbar.show('Thanks Message cannot be empty!');
        }
        await this.thanksToken.save();
      }

      if (this.galleryLayoutToken?.hasDirtyAttributes) {
        await this.galleryLayoutToken.save();
      }

      if (this.model.design.hasDirtyAttributes) {
        await this.model.design.save();
      }

      const block = this.arc.socialElement;
      if (block) {
        const promiseArray = [];

        if (this.facebookToken) {
          this.facebookToken.isDisabled = !block.data.facebook;
          this.facebookToken.title = block.data.facebookTitle;
          this.facebookToken.value = 'Facebook Description';
          this.facebookToken.mediaItemId = block.data.facebookMediaIemId ? +block.data.facebookMediaIemId : null;
          if (this.facebookToken.hasDirtyAttributes && (this.facebookToken.isDisabled || this.facebookToken.value))
            promiseArray.push(this.facebookToken.save());
        }

        if (this.emailToken) {
          this.emailToken.isDisabled = !block.data.email;
          this.emailToken.title = block.data.emailSubject;
          this.emailToken.value = block.data.emailDescription;
          if (this.emailToken.hasDirtyAttributes && (this.emailToken.isDisabled || this.emailToken.value))
            promiseArray.push(this.emailToken.save());
        }

        if (this.xToken) {
          this.xToken.isDisabled = !block.data.x;
          this.xToken.value = block.data.xDescription;
          if (this.xToken.hasDirtyAttributes && (this.xToken.isDisabled || this.xToken.value))
            promiseArray.push(this.xToken.save());
        }

        await RSVP.all(promiseArray);
      }
      this.updateDirtyFlag();
    } catch (e) {
      this.snackbar.exception(e);
    }
  });
}
